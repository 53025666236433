/**
 *
 * "Provides the sideliners as group of 2D boxes in mobile display version
 *  calculated from deck revisions data from database"
 *
 * @file   mobileDeckCanvas\SideLiner.js
 * @author Lateral
 * @since  2023
 */

import { Box, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import BigNumber from 'bignumber.js'
import { DeckMode } from 'common/deckMode'
import { getWearColour, pickHeatColour } from 'common/heatColours'
import { useMaintenance } from 'hooks'
import React, { forwardRef } from 'react'
import { useSelected } from 'hooks/useAreaSelection'

const Item = ({ selected, maintenance, heatMapData, item, kits, materialSelected, mode, onPanelClick, scale }) => {
  const ref = React.useRef(null)
  const isSelected =
    useSelected(ref) ||
    selected.filter(
      (s) => s.MaterialNumber == item.MaterialNumber && s.StartPosition == item.StartPosition && s.Side == item.Side
    ).length > 0
  const { actions, getAction } = useMaintenance()

  const theme = useTheme()

  const interactionColour = theme.palette.secondary.main
  const emptyColour = theme.palette.primary.dark
  const isMaterialSelected = materialSelected == item.MaterialNumber
  // const isSelected =
  //   selected.filter(
  //     (s) => s.MaterialNumber == item.MaterialNumber && s.StartPosition == item.StartPosition && s.Side == item.Side
  //   ).length > 0
  const kit = kits.find((k) => k.MaterialNumber === item.MaterialNumber)
  const height = `${new BigNumber(item.Height).multipliedBy(scale).toNumber()}em`
  const width = `${scale}em`

  let colour =
    isSelected || isMaterialSelected
      ? interactionColour
      : mode.includes(DeckMode.maintenance)
      ? emptyColour
      : kit?.Colour ?? theme.palette.primary.dark

  let maintenanceAction = null

  //if in Maintenance mode, change colours
  if (mode.includes(DeckMode.maintenance) && maintenance) {
    const startPosition = new BigNumber(item.StartPosition)
    const detail = maintenance.Details?.find(
      (d) => startPosition.isEqualTo(d.SideLiner?.StartPosition) && item.Side === d.SideLiner?.Side
    )

    if (detail) {
      maintenanceAction = getAction(detail.HistoryAction)

      if (
        (mode === DeckMode.maintenance && maintenanceAction.id === actions.NoChange.id) ||
        (mode === DeckMode.preMaintenance &&
          (detail.WorkingDepth || detail.WorkingDepth === 0) &&
          (detail.ApertureWidth || detail.ApertureWidth === 0))
      ) {
        colour = getWearColour(detail, theme)
      } else if (mode !== DeckMode.preMaintenance && maintenanceAction.id !== actions.NoChange.id) {
        colour = 'white'
      }
    }
  } else if (mode === DeckMode.heatMap) {
    const sideLinerHeatData = heatMapData.SideLiners.find(
      (s) => s.Side === item.Side && s.StartPosition === item.StartPosition
    )

    const wearCount = new BigNumber(sideLinerHeatData.WearCount)
    const total = heatMapData.Total.isEqualTo(0) ? new BigNumber(1) : heatMapData.Total
    const heatCount = total.minus(wearCount).dividedBy(total).times(100).toNumber()
    colour = pickHeatColour(heatCount, theme)
  }

  return (
    <Box data-selection={isSelected} data-item={JSON.stringify(item)} ref={ref}>
      {maintenanceAction &&
      maintenanceAction.id !== actions.NoChange.id &&
      (mode === DeckMode.maintenance || mode === DeckMode.postMaintenance) ? (
        <img
          src={`${maintenanceAction.svg}`}
          style={{ border: '1px solid black', height: `${height}`, width: `${width}`, verticalAlign: 'top' }}
          onClick={(e) => {
            e.stopPropagation()
            onPanelClick(item, e)
          }}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: `${colour}`,
            border: '1px solid black',
            width: { width },
            height: { height },
            '&:hover': {
              backgroundColor: interactionColour
            }
          }}
          onClick={(e) => {
            e.stopPropagation()
            onPanelClick(item, e)
          }}
        />
      )}
    </Box>
  )
}

/*
 *
 */
export const SideLiner = forwardRef(function SideLiner_(
  { data, kits, maintenance, materialSelected, onPanelClick, selected, scale, side, mode, presentation, heatMapData },
  ref
) {
  /**
   * Generates a Sideliner boxes based on incoming deck rivion data.
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {object[]} kits - Item details including material details of panels and sideliners
   * @param {number} materialSelected - Selected sideliner material number
   * @param {} onPanelClick - Action to click on a panel/sideliners. Same action applicable for panels and sideliners
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} scale - Scale of the canvas
   * @param {string} side - Which side Left?Right.
   * @param {boolean} presentation - In maintenance mode presentation = True to show high contrast texts
   * @param {string} mode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @returns {object} - React group of 2D boxes
   */

  const left = side === 'left' ? '0.2em' : '1em'
  const right = side === 'right' ? '0' : '1em'

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ marginLeft: `${left}`, marginRight: `${right}`, height: 'fit-content', width: 'fit-content' }}>
      <Typography fontSize={12} sx={[{ width: '100%', textAlign: 'center' }, presentation ? { color: 'black' } : null]}>
        {side === 'left' ? 'RS' : 'LS'}
      </Typography>
      <div ref={ref}>
        {data.map((item) => {
          return (
            <Item
              key={`${item.Side}-${item.StartPosition}`}
              maintenance={maintenance}
              heatMapData={heatMapData}
              item={item}
              kits={kits}
              materialSelected={materialSelected}
              mode={mode}
              onPanelClick={onPanelClick}
              scale={scale}
              selected={selected}
            />
          )
        })}
      </div>
    </Stack>
  )
})
