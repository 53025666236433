import { useSelectionContext } from 'components/selection/SelectionContext'
import { accent, secondAccent } from 'mainTheme'
import React from 'react'

function drawSelectionBox(boxElement, start, end) {
  const b = boxElement
  if (end.x > start.x) {
    b.style.left = start.x + 'px'
    b.style.width = end.x - start.x + 'px'
  } else {
    b.style.left = end.x + 'px'
    b.style.width = start.x - end.x + 'px'
  }

  if (end.y > start.y) {
    b.style.top = start.y + 'px'
    b.style.height = end.y - start.y + 'px'
  } else {
    b.style.top = end.y + 'px'
    b.style.height = start.y - end.y + 'px'
  }
}

const boxElement = document.createElement('div')
boxElement.style.position = 'fixed'
boxElement.style.borderRadius = '10px'
boxElement.style.border = `2px dashed ${secondAccent}`
boxElement.style.opacity = 0.5
boxElement.style.backgroundColor = accent

export const useAreaSelection = ({ containerRef, isMultiSelectEnabled }) => {
  const [mouseDown, setMouseDown] = React.useState(false)
  const [selection, setSelection] = React.useState(null)
  const [drawArea, setDrawArea] = React.useState({
    start: undefined,
    end: undefined
  })

  const handleMouseMove = (e) => {
    document.body.style.userSelect = 'none'
    setDrawArea((prev) => ({
      ...prev,
      end: {
        x: e.clientX,
        y: e.clientY
      }
    }))
  }

  const handleMouseDown = (e) => {
    const containerElement = containerRef.current

    setMouseDown(true)

    if (containerElement && containerElement.contains(e.target)) {
      document.addEventListener('mousemove', handleMouseMove)
      setDrawArea({
        start: {
          x: e.clientX,
          y: e.clientY
        },
        end: {
          x: e.clientX,
          y: e.clientY
        }
      })
    }
  }

  const handleMouseUp = () => {
    document.body.style.userSelect = 'initial'
    document.removeEventListener('mousemove', handleMouseMove)
    setMouseDown(false)
    // set selection
  }

  React.useEffect(() => {
    if (!isMultiSelectEnabled) {
      setDrawArea({
        start: undefined,
        end: undefined
      })
      return
    }
    const containerElement = containerRef.current
    if (containerElement) {
      containerElement.addEventListener('mousedown', handleMouseDown)
      document.addEventListener('mouseup', handleMouseUp)

      return () => {
        containerElement.removeEventListener('mousedown', handleMouseDown)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [isMultiSelectEnabled])

  React.useEffect(() => {
    const { start, end } = drawArea
    if (start && end && boxElement && isMultiSelectEnabled) {
      drawSelectionBox(boxElement, start, end)
      setSelection(boxElement.getBoundingClientRect())
    }
  }, [drawArea, isMultiSelectEnabled])

  React.useEffect(() => {
    const containerElement = containerRef.current
    const selectionBoxElement = boxElement
    if (containerElement && selectionBoxElement) {
      if (mouseDown && isMultiSelectEnabled) {
        if (!document.body.contains(selectionBoxElement)) {
          containerElement.appendChild(selectionBoxElement)
        }
      } else if (containerElement.contains(selectionBoxElement)) {
        containerElement.removeChild(selectionBoxElement)
      }
    }
  }, [mouseDown, containerRef, boxElement, isMultiSelectEnabled])

  return [selection, setSelection]
}

export const useSelected = (elementRef) => {
  const [isSelected, setIsSelected] = React.useState(false)
  const { selection } = useSelectionContext()
  React.useEffect(() => {
    if (!elementRef.current || !selection) {
      setIsSelected(false)
    } else {
      const a = elementRef.current.getBoundingClientRect()
      const b = selection
      setIsSelected(!(a.y + a.height < b.y || a.y > b.y + b.height || a.x + a.width < b.x || a.x > b.x + b.width))
    }
  }, [selection])

  return isSelected
}
