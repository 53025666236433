import { useAreaSelection } from 'hooks/useAreaSelection'
import React, { createContext, useContext, useEffect } from 'react'

export const SelectionContext = createContext({})

export const useSelectionContext = () => useContext(SelectionContext)

export const SelectionContextProvider = ({ isMultiSelectEnabled, selectContainerRef, onPanelClick, children }) => {
  const [selection, setSelection] = useAreaSelection({ containerRef: selectContainerRef, isMultiSelectEnabled })

  useEffect(() => {
    if (!isMultiSelectEnabled) {
      return
    }

    const selectedItems = document.querySelectorAll("[data-selection='true']")
    const selected = []
    for (const item of selectedItems) {
      const itemData = JSON.parse(item.dataset.item)
      selected.push(itemData)
    }
    onPanelClick(selected)
  }, [selection, isMultiSelectEnabled])

  return (
    <SelectionContext.Provider
      value={{
        selection,
        setSelection
      }}>
      {children}
    </SelectionContext.Provider>
  )
}
