/**
 *
 * "CRUD (Create, read, update, and delete) functions for Users in Cognito"
 *
 * @file   adminDatastore.js
 * @author Lateral
 * @since  2023
 */

import { Auth, API } from 'aws-amplify'
import { useQuery } from '@tanstack/react-query'

const apiName = 'AdminQueries'

const defaultHeader = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  }
}

export const listUsers = async () => {
  /**
   *
   * Get user list
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  let path = '/listUsersExtended'
  let myInit = {
    headers: await defaultHeader()
  }
  const { ...rest } = await API.get(apiName, path, myInit)
  return rest
}

export const loadAndExtendUsers = async () => {
  /**
   *
   * Load user list and extend by user roles
   *
   * @function
   * @async
   * @returns {object} - Extended user list
   */
  const userResult = await listUsers()
  let users = Object.keys(userResult).map((k) => userResult[k])
  return users
}

export const listGroups = async () => {
  /**
   *
   * Get user group list
   *
   * @function
   * @async
   * @returns {object} - Group list
   */
  let path = '/listGroups'
  let myInit = {
    headers: await defaultHeader()
  }
  const { ...rest } = await API.get(apiName, path, myInit)
  return rest
}

export const createUser = async (email, firstName, lastName, companyName, sites) => {
  /**
   *
   * Create new user in datastore
   *
   * @param {string} email - Email of new user
   * @param {string} firstName - New user's first name
   * @param {string} lastName - New user's last name
   * @param {string} companyName - New user's company name
   *
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  let path = '/adminCreateUser'
  let myInit = {
    headers: await defaultHeader(),
    body: {
      username: email,
      attributes: [
        {
          Name: 'given_name',
          Value: firstName
        },
        {
          Name: 'family_name',
          Value: lastName
        },
        {
          Name: 'email',
          Value: email
        },
        {
          Name: 'dev:custom:customerName_',
          Value: companyName
        },
        {
          Name: 'dev:custom:sites_',
          Value: sites
        }
      ]
    }
  }
  const { ...rest } = await API.post(apiName, path, myInit)
  return rest
}

export const adminUpdateUserAttributes = async (userName, firstName, lastName, companyNames, sites) => {
  /**
   *
   * Update user in datastore
   *
   * @param {string} userName - User id/usrename
   * @param {string} firstName - New user's first name
   * @param {string} lastName - New user's last name
   *
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  let path = '/adminUpdateUserAttributes'
  let myInit = {
    headers: await defaultHeader(),
    body: {
      username: userName,
      attributes: [
        {
          Name: 'given_name',
          Value: firstName
        },
        {
          Name: 'family_name',
          Value: lastName
        },
        {
          Name: 'dev:custom:customerName_',
          Value: companyNames
        },
        {
          Name: 'dev:custom:sites_',
          Value: sites
        }
      ]
    }
  }
  const { ...rest } = await API.post(apiName, path, myInit)
  return rest
}

export const addUserToGroup = async (userName, groupName) => {
  let path = '/addUserToGroup'
  let myInit = {
    headers: await defaultHeader(),
    body: {
      username: userName,
      groupname: groupName
    }
  }
  const { ...rest } = await API.post(apiName, path, myInit)
  return rest
}

export const removeUserFromGroup = async (userName, groupName) => {
  /**
   *
   * Add a user to a group
   *
   * @param {string} userName - User id/usrename
   * @param {string} groupName - Group name of the user
   *
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  let path = '/removeUserFromGroup'
  let myInit = {
    headers: await defaultHeader(),
    body: {
      username: userName,
      groupname: groupName
    }
  }
  const { ...rest } = await API.post(apiName, path, myInit)
  return rest
}

export const disableUser = async (userName) => {
  /**
   *
   * Disable a user
   *
   * @param {string} userName - User id/usrename
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  let path = '/disableUser'
  let myInit = {
    headers: await defaultHeader(),
    body: {
      username: userName
    }
  }
  const { ...rest } = await API.post(apiName, path, myInit)
  return rest
}

export const enableUser = async (userName) => {
  /**
   *
   * Enables a user
   *
   * @param {string} userName - User id/usrename
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  let path = '/enableUser'
  let myInit = {
    headers: await defaultHeader(),
    body: {
      username: userName
    }
  }
  const { ...rest } = await API.post(apiName, path, myInit)
  return rest
}

export const useGetUsers = () => {
  /**
   *
   * Get extended role of the user in admin access
   *
   *
   * @function
   * @async
   * @returns {object[]} - Extended user list
   */
  const usersQuery = useQuery(['users'], loadAndExtendUsers, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    keepPreviousData: true
  })

  const value = {
    usersQuery
  }
  return value
}

export const listMaterials = async () => {
  return API.get(apiName, '/materials', {
    headers: await defaultHeader()
  })
}

export const updateMaterial = async (id, data) => {
  return API.patch(apiName, `/materials/${id}`, {
    headers: await defaultHeader(),
    body: data
  })
}

export const saveMaterial = async (data) => {
  return API.post(apiName, '/materials', {
    headers: await defaultHeader(),
    body: data
  })
}
